import logo from './logo.svg';
import './App.css';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import logoIcon from '../src/images/Gensys_Logo_2.png';
import aboutIcon from '../src/images/about.png';
import workForceIcon from '../src/images/utilization.png';
import dataIcon from '../src/images/data-collection.png';

import preventIcon from '../src/images/medical-insurance_8127272.png';
import trainingIcon from '../src/images/instructor_2643361.png';
import integrationIcon from '../src/images/collaboration_5371017.png';

import serviceImage from '../src/images/project-manager.png';
import projectIcon from '../src/images/clarification.png';
import installIcon from '../src/images/drivers.png';
import supportIcon from '../src/images/technical-support.png';
import developmentIcon from '../src/images/challenge_12061828.png';


import aboutUsImage from '../src/images/pexels-fauxels-3183197.jpg';

import FireFlyImage from '../src/images/Firefly.jpg';
import IdemiaImage from '../src/images/morphowave-sp-xp-idemia.jpg';
import HikVisionImage from '../src/images/HikVision.png';
import AlcoScanImage from '../src/images/Alcoscan.jpg';
import TrakaImage from '../src/images/traka.jpg';

function App() {
  return (
    <div className="App">

      <nav className="navbar navbar-expand-lg customNavbar">
        <div class="container">
          <a className="navbar-brand" href="#"><img class="logoIcon" src={logoIcon} alt="Logo"/></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse text-end navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav me-auto text-end justify-content-end sticky-top">
              <a className="nav-link active" aria-current="page" href="#">Home</a>
              <a className="nav-link" href="#services"> Our Servicess </a>
              <a className="nav-link" href="#solutions"> Solutions </a>
              <a className="nav-link" href="#aboutUs"> About Us </a>
              <a className="nav-link" href="#products"> Products </a>
              <a className="nav-link" href="#contactUs"> Contact Us </a>
              
            </div>
          </div>
        </div>
      </nav>

      <div className='header container-fluid'>
    
        <div className='headerDiv container text-start'>
          <h3 className='h2-header'> We specialise in Integrated Time & Attendance, Access Control, Health & Safety and Compliance 
            Solutions for mines across South Africa.</h3>
            <button className='btn btn-primary btn-lg'> Know More </button>
        </div>

      </div>

      <div className="container-fluid servicesContainer" id="services">
        <h1 className='servicesHeading'> Our Services </h1>

        <div className='container '>
          <div className='row rowContainer'> 
              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={serviceImage} alt="Logo"/>
                  <h4> Management Consulting </h4>
                  <p>Product Advice, Change Management, Business Process Mapping.</p>
                </div>
              
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={projectIcon} alt="Logo"/>
                  <h4> Project Management </h4>
                  <p> Implementation, Quality Control, Long Term business partnerships. </p>
                </div>              
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={installIcon} alt="Logo"/>
                  <h4> Installation </h4>
                  <p>Hardware Installation, Software Implementation.</p>
                </div>            
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={supportIcon} alt="Logo"/>
                  <h4> 24/7 Support </h4>
                  <p>Hardware, Software and Interfaces. On Site and Remote Support.</p>
                </div>              
              </div>
        </div>

        <div className='row rowContainer'> 
              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={preventIcon} alt="Logo"/>
                  <h4> Preventative Maintenance </h4>
                  <p> Continuous Monitoring, Scheduled inspections, Hardware Servicing. </p>
                </div>
              
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={trainingIcon} alt="Logo"/>
                  <h4> Training  </h4>
                  <p> Customised Training for each business function level. Initial Training, Re-Training. </p>
                </div>              
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={integrationIcon} alt="Logo"/>
                  <h4> Integration </h4>
                  <p> Custom integration to 3rd party systems. Integration to Data warehouses and reporting systems. </p>
                </div>            
              </div>

              <div className='col-lg-3 col-6 col-sm-6'>
                <div className="serviceTile">
                <img class="serviceImage" src={developmentIcon} alt="Logo"/>
                  <h4> Development  </h4>
                  <p>Continuous Innovative functionality development. Systems adapted to fit clients business requirements.</p>
                </div>              
              </div>
        </div>
      </div>



      </div>

      {/*  Our Solutions */}

      <div className='container-fluid'>
        <div className='solutionsContainer' id="solutions">
          <h2 className='solutionsHeading'> Our Solutions </h2>
          <div className='row d-flew justify-content-center'>
            <div className='col-lg-2 col-6 col-sm-6 col-xs-6'>
              <div className='solutionsImage1'>
                  <h4 className='solutionsInnerHeading'> Access Control </h4>
              </div>
            </div>

            <div className='col-lg-2 col-6 col-sm-6 col-xs-6'>
              <div className='solutionsImage2'>
                  <h4 className='solutionsInnerHeading'> Time Management </h4>
              </div>
            </div>

            <div className='col-lg-2 col-6 col-sm-6 col-xs-6'>
              <div className='solutionsImage3'>
                  <h4 className='solutionsInnerHeading'> Compliance Management </h4>
              </div>
            </div>

            <div className='col-lg-2 col-6 col-sm-6 col-xs-6'>
              <div className='solutionsImage4'>
                  <h4 className='solutionsInnerHeading'> Healthy and Saftey Management </h4>
              </div>
            </div>

            <div className='col-lg-2 col-6 col-sm-6 col-xs-6'>
              <div className='solutionsImage5'>
                  <h4 className='solutionsInnerHeading'> WorkForce Management </h4>
              </div>
            </div>
          </div>

          {/* Second Row Attempt */}

        </div>
      </div>

      {/* About Us Section */}

      <div className='container-fluid aboutContainer' id="aboutUs">
        <h2> About Us </h2>
        <div className='container aboutUs'>
          <div className='row'>
          <div className='col-lg-4 col-4 col-sm-12 col-xs-12'>
              <img class="aboutUsImage" src={aboutUsImage} alt="Logo"/>
          </div>
          <div className='col-lg-8 col-8 col-sm-12 col-xs-12'>
            <div className='row aboutInnerRow'>
              <div className='col-3'>
                <img class="logoIcon" src={aboutIcon} alt="Logo"/>
              </div>
              <div className='col-9 text-start'>
                  <p> Gensys supplies, installs, supports and maintains workforce management systems to large, medium and 
                    small enterprises. The company has excellent in-house consulting, project management, software and 
                    hardware development skills backed up by over 30 years of experience in T & A, HR, access control and data collection.</p>
              </div>
            </div>

            <div className='row aboutInnerRow'>
              <div className='col-3'>
                <img class="logoIcon" src={workForceIcon} alt="Logo"/>
              </div>
              <div className='col-9 text-start'>
                  <p> Our workforce systems are designed to operate mission-critical environments, specifically in the Mining and Heavy Engineering Sectors.</p>
              </div>
            </div>

            <div className='row aboutInnerRow'>
              <div className='col-3'>
                <img class="logoIcon" src={dataIcon} alt="Logo"/>
              </div>
              <div className='col-9 text-start'>
                  <p>We are responsible to capture and process time worked, overtime, call-outs, etc. accurately for 
                    thousands of employees. Our systems are responsible to enforce health, safety and competence regulations.</p>
              </div>
            </div>

          </div>
        </div>
        </div>
  
      </div>

      <div className='container-fluid productsContainer' id="products"> 
        <h2 className='text-center'> Our Products </h2>
        <div className='container'>
          <div className='row d-flew justify-content-center'>
            <div className='col-lg-2 col-6 col-sm-6'>
              <img class="productImage" src={FireFlyImage} alt="Logo"/>
                  <h4> Firefly </h4>
                  <h6> Mining Hardened</h6>
            </div>

            
            <div className='col-lg-2 col-6 col-sm-6'>
              <img class="productImage" src={IdemiaImage} alt="Logo"/>
                  <h4> Idemia </h4>
                  <h6> Biometric Terminals </h6>
            </div>

            <div className='col-lg-2 col-6 col-sm-6'>
              <img class="productImage" src={HikVisionImage} alt="Logo"/>
                  <h4> HikVision </h4>
                  <h6> Face Thermal Terminals </h6>
            </div>

            <div className='col-lg-2 col-6 col-sm-6'>
              <img class="productImage" src={AlcoScanImage} alt="Logo"/>
                  <h4> AlcoScan </h4>
                  <h6> Entrance Breathalisers </h6>
            </div>

            <div className='col-lg-2 col-6 col-sm-6'>
              <img class="productImage" src={TrakaImage} alt="Logo"/>
                  <h4> Traka </h4>
                  <h6> Key Cabinets </h6>
            </div>

          </div>

        </div>
      </div>

      <div className='container-fluid contactContainer' id="contactUs">
        <h2> Contact Us </h2>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12 col-sm-12'>
            <div><iframe width="100%" height="400" frameborder="0" 
               scrolling="no" marginheight="0" marginwidth="0" 
              src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=8%20Precision%20St,%20Kya%20Sands,%20Johannesburg+(Gensys%20Workforce%20Systems)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              <a href="https://www.gps.ie/"> Gensys WorkForce Systems </a>
              </iframe></div>

            </div> 
            <div className='col-lg-8 col-12 col-sm-12'>
                <form className='text-start customForm'>
                  <div className='form-group formItem'>
                    <label className='formLabel'> Full Name </label>                              
                    <input name="text" className='form-control' type="email" placeholder="Enter your Full Name" />                           
                  </div>

                  <div className='form-group formItem'>
                    <label className='formLabel'> Email </label>
                    <input type="email" className='form-control' name="email" placeholder="Enter your email address"   />                           
                  </div>

                  <div className='form-group formItem'>
                    <label className='formLabel'> Message </label>
                    <textarea type="textarea" className='form-control' name="message" placeholder="Enter your message..."></textarea>                           
                  </div>
                    
                  <button  type="submit" className="btn btn-lg btn-primary">  Send Email </button>
                </form>
            </div>            
          </div>
        </div>

      </div>


      <div className="container-fluid footer">
        <div className="inner-footer container text-start">
          <div className="row">
            <div className="col-4 col-lg-4 col-md-4 col-sm-6 align-right">
              <h3> Quick Links </h3>

              
                <p> Home </p>
                <p> Services </p>
                <p> Solutions </p>
                <p> About Us</p>
                <p> Products </p>
                <p> Contact Us </p>
              
           
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-6">
              <h3> Our Address </h3>
              <p> 8 Precision St, Kya Sands, Johannesburg, 2169 </p>
              <p> +27 11 954 2042</p>
              <p> info@Gensys.co.za</p>
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-12">
              <h3> About Gensys </h3>
              <p> We specialize in Integrated Time & Attendance, Access Control, Health & Safety and Compliance Solutions for mines across South Africa.</p>
            </div>

          </div>
          <div className="row footerSpanRow">
              <span className='footerSpan'>  © 2024 Gensys. All Rights Reserved | Developed by Mdot-Creatives </span>         
          </div>
        </div>
      </div>




    </div>
  );
}

export default App;
